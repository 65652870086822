import {
  createSmartAccountClient,
  BiconomySmartAccountV2,
  PaymasterMode,
} from "@biconomy/account";
import { ethers } from "ethers";
import { contractABI } from "../contract/contractABI";
import { interactionABI } from "../contract/interactionABI";
import { chains } from "../chain";
import { getWalletBalance } from "./getWalletBalance";
import { toast } from "react-toastify";

export const sendTokensForaddFriend = async (authProvider) => {
    toast.info("Sending Add Friend Transaction. Please Wait.")
    const walletBalance = await getWalletBalance();
    if (walletBalance < 10) {
      toast.warn('Insufficient Wallet Balance');
      return;
    }

    const contractAddress = chains.dropdContractAdd;
    const interactionAddress = chains.interactionContractAdd;
    const provider = new ethers.providers.JsonRpcProvider(
        chains.providerUrl
    );
    const contractInstance = new ethers.Contract(
        contractAddress,
        contractABI,
        provider
    );
    const interactionInstance = new ethers.Contract(
        interactionAddress,
        interactionABI,
        provider
    )
    const walletAddress = localStorage.getItem("wallet_address");
    const approvedAmount = ethers.utils.parseEther("10");

  try {
    const gasPrice = await provider.getGasPrice();
    const increasedGasPrice = gasPrice.mul(110).div(100);

    const minTx1 = await contractInstance.populateTransaction.approve(interactionAddress, approvedAmount);
    
    const tx1 = {
      to: contractAddress,
      data: minTx1.data,
      gasPrice: increasedGasPrice
    };
    const minTx2 = await interactionInstance.populateTransaction.sendFriendRequest(authProvider.receiverAddress);
    
    const tx2 = {
      to: interactionAddress,
      data: minTx2.data,
      gasPrice: increasedGasPrice
    };
    
    //@ts-ignore
    let i = 0;
    const userOpResponse = await authProvider.authProvider?.sendTransaction([tx1,tx2], {
      paymasterServiceData: { mode: PaymasterMode.SPONSORED },
      nonceOptions: {nonceKey: i++ }
    });
    //@ts-ignore
    const { transactionHash } = await userOpResponse.waitForTxHash();

    const transactionReceipt = await userOpResponse.wait();
    
    if (transactionReceipt.success == "true") {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};
