import * as Sentry from "@sentry/react";

export const logError = (error, context = {}) => {
  const status = error.response?.status;
  const message = error.response?.data?.message || "Unknown error occurred";

  // Format the main error message as the title
  const title = `[Error ${status}] ${message}`;

  // Log to Sentry
  Sentry.captureException(new Error(title));

  // Uncomment and use if additional details are needed in the future
  /*
  Sentry.captureException(new Error(title), {
    extra: {
      status,
      statusText: error.response?.statusText,
      url: error.config?.url,
      method: error.config?.method,
      headers: error.config?.headers,
      ...context, // Additional custom context
    },
  });
  */
};
