import {
  createSmartAccountClient,
  BiconomySmartAccountV2,
  PaymasterMode,
} from "@biconomy/account";
import { ethers } from "ethers";
import { flicsABI } from "../contract/flicsABI";
import { flicsContractABI } from "../contract/flicsContractABI";

import { chains } from "../chain";
import { toast } from "react-toastify";

import { getFLicsStatus } from "./getFlicsStatus";

export const claimCashbackFlics = async (
  authProvider,
  flicsInitiator,
  flicsAddress
) => {
  debugger;
  toast.info("Sending ROCCA Cashback Transaction. Please Wait.");
  const provider = new ethers.providers.JsonRpcProvider(chains.providerUrl);

  const flicsInstance = new ethers.Contract(
    chains.flicsPoolContractAdd,
    flicsABI,
    provider
  );

  const flicsContractInstance = new ethers.Contract(
    flicsAddress,
    flicsContractABI,
    provider
  );

  const flicsStatus = await getFLicsStatus(flicsAddress);

  try {
    const gasPrice = await provider.getGasPrice();
    const increasedGasPrice = gasPrice.mul(110).div(100);

    const minTx1 = await flicsContractInstance.populateTransaction.endFLICS();

    const tx1 = {
      to: flicsAddress,
      data: minTx1.data,
      gasPrice: increasedGasPrice
    };

    const minTx2 = await flicsInstance.populateTransaction.fullRefundReward(
      flicsInitiator,
      flicsAddress
    );

    const tx2 = {
      to: chains.flicsPoolContractAdd,
      data: minTx2.data,
      gasPrice: increasedGasPrice
    };

    //@ts-ignore
    let i = 0;
    const userOpResponse =
      flicsStatus === 4
        ? await authProvider?.sendTransaction(tx2, {
            paymasterServiceData: { mode: PaymasterMode.SPONSORED },
          })
        : await authProvider?.sendTransaction([tx1,tx2], {
            paymasterServiceData: { mode: PaymasterMode.SPONSORED },
            nonceOptions: {nonceKey: i++ }
          });
    //@ts-ignore
    const { transactionHash } = await userOpResponse.waitForTxHash();

    const transactionReceipt = await userOpResponse.wait();
    
    if (transactionReceipt.success == "true") {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};
