import React from "react";
import PropTypes from 'prop-types';
import { Row, Col } from "react-bootstrap";

import CustomButton from 'src/components/Button/button.jsx';

import './index.css';


const Popup = ({
    message,
    customAction,
    cancelAction,
    children,
}) => {
    return (
        <div className="popup-wrapper">
            <div className="popup">
                <span>
                    {message}
                </span>
                <div style={{ textAlign: 'center' }}>
                    {children}
                </div>
                <Row>
                    <Col xs={6}>
                        <CustomButton style={{ width: "50%" }} onClick={customAction}>DELETE</CustomButton>
                    </Col>
                    <Col xs={6}>
                        <CustomButton style={{ width: "50%" }} onClick={cancelAction}>Cancel</CustomButton>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

Popup.prototype = {
    message: PropTypes.string,
    customAction: PropTypes.func,
    cancelAction: PropTypes.func,
};

Popup.defaultProps = {
    message: '',
    customAction: () => { },
    cancelAction: () => { },
};

export default Popup;