import React, { useState, useEffect } from "react"
import { useSpinner } from "../../context/loaderContext/globalSpinnerContext";
import { Grid, InputBase, TextField, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";
import CreateClubDetails from "./components/createclubdetails"
import CreateClubPhoto from "./components/createclubphoto"
import FloatingToolbar from "../../components/FloatingToolbar"
import { getWalletBalance } from "src/web3/getWalletBalance";

function CreateClub() {
  const spinner = useSpinner();
  const [sliderPage, setSliderPage] = useState("details")
  const [clubName, setClubName] = useState("");
  const [description, setDescription] = useState("");
  const [interests, setInterests] = useState("");
  const [country, setCountry] = useState(null);
  const [state, setState] = useState(null);
  const [city, setCity] = useState(null);
  const [locality, setLocality] = useState(null);
  const [joiningFee, setJoiningFee] = useState("");
  const [walletBalance, setWalletBalance] = useState(0);
  const [walletBalancePopupOpen, setWalletBalancePopupOpen] = useState(false);

  const CLUB_CREATION_FEE = 50; 

  useEffect(() => {
    const fetchWalletBalance = async () => {
      try {
        spinner.setLoadingState(true);
        const balanceResult = await getWalletBalance();
        setWalletBalance(balanceResult);
        if (balanceResult < CLUB_CREATION_FEE) {
          setWalletBalancePopupOpen(true);
        }
      } catch (error) {
        console.error("Failed to fetch wallet balance", error);
      } finally {
        spinner.setLoadingState(false);
      }
    };
    fetchWalletBalance();
  }, []);

  const handleAddFunds = () => {
    // Implement fund addition logic or redirect to wallet page
    handleCloseWalletBalancePopup();
  };

  const handleCloseWalletBalancePopup = () => {
    setWalletBalancePopupOpen(false);
  };

  const renderSlider = () => {
    switch (sliderPage) {
      case "details":
        return (
          <CreateClubDetails
            setSliderPage={setSliderPage}
            setClubName={setClubName}
            clubName={clubName}
            description={description}
            setDescription={setDescription}
            setInterests={setInterests}
            interests={interests}
            setCountry={setCountry}
            country={country}
            setState={setState}
            state={state}
            setCity={setCity}
            city={city}
            locality={locality}
            setLocality={setLocality}
            setJoiningFee={setJoiningFee}
            joiningFee={joiningFee}
            walletBalance={walletBalance}
          />
        );
      case "photo":
        return (
          <CreateClubPhoto
            clubName={clubName}
            interests={interests}
            description={description}
            country={country}
            state={state}
            city={city}
            locality={locality}
            joiningFee={joiningFee}
            walletBalance={walletBalance}
            setSliderPage={setSliderPage}
          />
        );
      default:
        return null;
    }
  }

  return (
    <div style={{display: "flex", flexDirection: "column"}}>
      {renderSlider()}

      <Dialog
        open={walletBalancePopupOpen}
        onClose={handleCloseWalletBalancePopup}
      >
        <DialogTitle>Insufficient Wallet Balance</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Club Creation Fee: {CLUB_CREATION_FEE} DRPD
            <br />
            Your Current Balance: {walletBalance} DRPD
            <br />
            You need to add more funds to your wallet to create a club.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseWalletBalancePopup} color="primary">
            Close
          </Button>
          <Button onClick={handleAddFunds} color="primary">
            Add Funds
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default CreateClub;