import {
    createSmartAccountClient,
    BiconomySmartAccountV2,
    PaymasterMode,
  } from "@biconomy/account";
  import { ethers } from "ethers";
  import { interactionABI } from "../contract/interactionABI"; 
  import { chains } from "../chain";
  import { toast } from "react-toastify";
  
  export const cancelFriend = async (authProvider) => {
      debugger
      toast.info("Sending Cancel Friend Transaction. Please Wait.")
      const interactionAddress = chains.interactionContractAdd;
      const provider = new ethers.providers.JsonRpcProvider(
          chains.providerUrl
      );
      const interactionInstance = new ethers.Contract(
          interactionAddress,
          interactionABI,
          provider
      )
  
    try {
      const gasPrice = await provider.getGasPrice();
      const increasedGasPrice = gasPrice.mul(110).div(100);

      const minTx = await interactionInstance.populateTransaction.cancelFriendRequest(authProvider.receiverAddress);
  
      const tx1 = {
        to: interactionAddress,
        data: minTx.data,
        gasPrice: increasedGasPrice
      };
  
      //@ts-ignore
      const userOpResponse = await authProvider.authProvider?.sendTransaction(tx1, {
        paymasterServiceData: { mode: PaymasterMode.SPONSORED },
      });
      //@ts-ignore
      const { transactionHash } = await userOpResponse.waitForTxHash();
  
      const transactionReceipt = await userOpResponse.wait();
    
      if (transactionReceipt.success == "true") {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };