import { Navigate, useLocation } from 'react-router-dom';

const AuthGuard = ({ children }) => {
  const token = localStorage.getItem('token');
  const location = useLocation();

  if (!token) {
    // Redirect to login page and save the attempted URL
    return <Navigate to="/" state={{ returnUrl: location.pathname }} />;
  }

  return children;
};

export default AuthGuard;