import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

// import assets
import dropdTokanIcon from "src/assets/images/dropdtokanicon.svg";
import incomeGiver from "src/assets/images/incomegiver.svg";
import swapIcon from "src/assets/images/swapicon.svg";

// import components
import TimelineNav from "../../components/timelinenav/timelineNav";

import { GET_CLAIM_REWARD_SIGNATURE } from "../../axios/GET_API";
import { claimReward } from "../../web3/ClaimReward";

import { useAuth } from "src/context/web3authProvider/AuthContext";
import useWeb3Auth from "src/web3/useWeb3Auth";

import {
  useSpinner,
  UseSpinnerProvider,
} from "../../context/loaderContext/globalSpinnerContext";

import "./myearning.css";
import { ethers } from "ethers";

function MyEarning() {
  var { authProvider } = useAuth();
  const { initWallet } = useWeb3Auth();

  const history = useNavigate();
  const spinner = useSpinner();

  const changeToTransaction = () => {
    history("/transactions");
  };

  const handleClaim = async () => {
    spinner.setLoadingState(true);
    try {
      if (!authProvider) {
        const smartWallet = await initWallet();
        authProvider = smartWallet;
      }

      const response = await GET_CLAIM_REWARD_SIGNATURE();
      const sigV = response.v;
      const sigR = response.r;
      const sigS = response.s;
      const nonce = response.nonce;
      const time = response.timestamp;
      const claimValue = response.claim_amount;
      const nextClaimTime = response.next_claim_time;
      const sign = [sigV, sigR, sigS, nonce, time];
      const txReceipt = await claimReward(authProvider, claimValue, nextClaimTime, sign);
    } catch (error) {
      console.log(error);
    } finally {
      spinner.setLoadingState(false);
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div className="page-page-wrapper flics-page-container">
        <div className="pnkbg">
          <TimelineNav />
        </div>

        <div className="inner-pages-container">
          <div className="half-pnk">
            <div className="wallet-title-menu-wrap">
              <h1 className="page-title">My Income</h1>
            </div>
          </div>

          <div className="inner-pages-container-wrap">
            <div className="round-edge-col">
              <div className="add-drpd-btn-wrap">
                <span>Total Income</span>
              </div>

              <div className="balance">
                <span>
                  <img src={dropdTokanIcon} />
                </span>
                <span>2000</span>
              </div>
            </div>

            <div className="income-card-container">
              <div className="income-popup-conainer">
                <div className="add-drpd-btn-wrap">
                  <span>My Expected Income</span>
                  <span></span>
                </div>

                <div className="balance">
                  <span>
                    <img src={dropdTokanIcon} />
                  </span>
                  <span>773</span>
                </div>

                <div
                  style={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#6A7587",
                    textAlign: "center",
                    marginBottom: "10px",
                  }}
                >
                  <span>this month</span>
                </div>

                <div className="income-formula">
                  <span>Likes</span>
                  <span className="txt-blue sign">+</span>
                  <span>Time Spent</span>
                  <span className="txt-blue sign">=</span>
                  <span style={{ marginRight: "10px" }}>
                    <img src={dropdTokanIcon} />
                  </span>
                  <span className="txt-blue">773</span>
                </div>
              </div>

              <div className="wallet-monthly-table">
                <table>
                  <tbody>
                    <tr style={{ backgroundColor: "#ffffff" }}>
                      <th colSpan="3">This month</th>
                      <th>Avg. Unit value</th>
                      <th>Total</th>
                    </tr>
                    <tr style={{ backgroundColor: "#FAF3FF" }}>
                      <td className="lbl">Likes</td>
                      <td className="val">256</td>
                      <td className="lbl">x</td>
                      <td className="val">0.5</td>
                      <td className="total">128</td>
                    </tr>
                    <tr style={{ backgroundColor: "#FAF3FF" }}>
                      <td className="lbl">Time Spent</td>
                      <td className="val">860 mins</td>
                      <td className="lbl">x</td>
                      <td className="val">0.75</td>
                      <td className="total">645</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  backgroundColor: "#ffffff",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
              >
                <span
                  style={{ fontSize: 14, lineHeight: "20px", color: "#46164D" }}
                >
                  Expected Income
                </span>
                <span
                  style={{
                    fontSize: 14,
                    lineHeight: "16px",
                    fontWeight: "600",
                    display: "flex",
                    flexDirction: "row",
                  }}
                >
                  <img
                    style={{
                      width: "12px",
                      marginTop: "auto",
                      marginBottom: "auto",
                      marginRight: "10px",
                    }}
                    src={dropdTokanIcon}
                  />
                  <span style={{ marginTop: "auto", marginBottom: "auto" }}>
                    773
                  </span>
                </span>
              </div>
              <div className="monthly-income-wrp">
                <span>
                  <img src={incomeGiver} />
                </span>
                <span>
                  <span className="lbl">Feb</span>
                  <span>
                    <img src={dropdTokanIcon} />
                    356
                  </span>
                </span>
                <span>
                  <span className="lbl">Mar</span>
                  <span>
                    <img src={dropdTokanIcon} />
                    432
                  </span>
                </span>
                <span>
                  <span className="lbl">Apr</span>
                  <span>
                    <img src={dropdTokanIcon} />
                    863
                  </span>
                </span>
              </div>
            </div>

            <div className="wallet-tabs-container">
              <button className="transaction" onClick={changeToTransaction}>
                <span>Transactions</span>{" "}
                <span className="tab-icon">
                  <img src={swapIcon} />
                </span>
              </button>
            </div>
          </div>

          <div className="inner-pages-container-wrap">
            <div className="button-wrap">
              <Button
                onClick={handleClaim}
                variant="contained"
                style={{
                  width: "100%",
                  color: "white",
                  backgroundColor: "#C387C3",
                  borderRadius: "12px",
                  marginTop: "10px",
                  marginBottom: "10px",
                  boxShadow: "none",
                }}
              >
                Claim
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyEarning;
