import React, { useState, useEffect } from "react";
import { Dialog, DialogContent, Grid } from '@mui/material';
import TimelineNav from "../../components/timelinenav/timelineNav";
import TransactionConfirmationPopup from "../../components/TransactionConfirmationPopup/TransactionConfirmationPopup";


import "./clubpage.css";
import PostCard from "../../components/PostCard/PostCard";
import moment from "moment";
import { USER_VIEW_PROFILE, VIEW_CLUB } from "../../axios/GET_API";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { USER_JOIN_CLUB } from "../../axios/POST_API";
import Friendbutton from "../../components/Friendbutton/Friendbutton";
import { CLUB_MEMBER_LIST } from "../../axios/GET_API";
import SkeletonLoader from "../../components/SkeletonLoader/Profileskeleton/Profileskeleton";
import { useSpinner } from "../../context/loaderContext/globalSpinnerContext";
import { sendTokensJoinClub } from "src/web3/JoinClub";
import { sendTokensCreateClub } from "src/web3/CreateClub";
import { getWalletBalance } from "src/web3/getWalletBalance";
import { useAuth } from 'src/context/web3authProvider/AuthContext';
import useWeb3Auth from "src/web3/useWeb3Auth";

const { REACT_APP_CDN_HOST } = process.env;

function Clubpage() {
  const { cid } = useParams();
  const history = useNavigate();
  const spinner = useSpinner();

  const { authProvider } = useAuth();
  const { initWallet } = useWeb3Auth();

  const [clubData, setClubData] = useState();
  const [skeletonLoader, setSkeletonLoader] = useState(false);
  const [activeTab, setActiveTab] = useState('posts');
  const [membersData, setMembersData] = useState();
  const [retryCount, setRetryCount] = useState(0);
  const [isRetryingPayment, setIsRetryingPayment] = useState(false);

  const MAX_RETRY_ATTEMPTS = 5;
  const RETRY_INTERVAL = 10000; // 10 seconds
  
  // New state for transaction confirmation popup
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [transactionSuccess, setTransactionSuccess] = useState(false);
  const transactionDescription = `You have successfully Joined the Club ${clubData?.data?.club_info?.club_name} worth ${clubData?.data?.club_info?.joining_fees / 1e18} DRPD`;


  const viewClub = async (isRetry = false) => {
    try {
      setSkeletonLoader(true);
      const response = await VIEW_CLUB(cid);
      setSkeletonLoader(false);
      setClubData(response);

      // If status is pending and not a manual retry, start auto-retry mechanism
      if (response?.data?.club_info?.status === 'pending' && !isRetry) {
        startStatusCheckInterval();
      }
    } catch (error) {
      setSkeletonLoader(false);
      console.error(error);
    }
  };

  const startStatusCheckInterval = () => {
    const checkStatusInterval = setInterval(async () => {
      if (retryCount >= MAX_RETRY_ATTEMPTS) {
        clearInterval(checkStatusInterval);
        return;
      }

      try {
        const response = await VIEW_CLUB(cid);
        if (response?.data?.club_info?.status !== 'pending') {
          clearInterval(checkStatusInterval);
          setClubData(response);
          setRetryCount(0);
        } else {
          setRetryCount(prev => prev + 1);
        }
      } catch (error) {
        console.error("Status check error:", error);
        clearInterval(checkStatusInterval);
      }
    }, RETRY_INTERVAL);
  };

  const clubMemberList = async () => {
    try {
      const response = await CLUB_MEMBER_LIST(cid);
      setMembersData(response);
    } catch (error) {
      console.error(error);
    }
  };

  const handleJoinClubWeb3 = async () => {
    spinner.setLoadingState(true);

    try {
      if (!authProvider) {
        authProvider = await initWallet();
      }

      const profileData = await USER_VIEW_PROFILE();
      const txReceipt = await sendTokensJoinClub(
        authProvider, 
        clubData?.data?.club_info?.joining_fees, 
        cid, 
        clubData?.data?.club_info?.owner?.wallet_address
      );

      if (txReceipt) {
        // await USER_JOIN_CLUB(profileData.data.did, cid);
        setShowConfirmationPopup(true);
        setTransactionSuccess(true);
        
        await viewClub(); // Refresh club data after joining
        history(`/club/${cid}`);
      }
    } catch (error) {
      console.error(error);
      alert(error.message || "Failed to join club");
    } finally {
      spinner.setLoadingState(false);
    }
  };

  const handleRetryPayment = async () => {
    setIsRetryingPayment(true);
    spinner.setLoadingState(true);

    try {
      let wallet = authProvider;
      if (!wallet) {
        wallet = await initWallet();
      }

      const walletBalance = await getWalletBalance(wallet);
      const creationFee = "50";

      await sendTokensCreateClub(
        wallet, 
        creationFee, 
        cid, 
        walletBalance
      );

      // Manually trigger a view club refresh with retry flag
      await viewClub(true);
    } catch (error) {
      console.error("Retry payment error:", error);
      alert(error.message || "Failed to retry payment");
    } finally {
      setIsRetryingPayment(false);
      spinner.setLoadingState(false);
    }
  };

  useEffect(() => {
    viewClub();
    clubMemberList();
  }, [cid]);

  const getImageUrl = (imagePath) => {
    return imagePath 
      ? `${REACT_APP_CDN_HOST}${imagePath.replace("//", "/")}` 
      : '/path/to/default/image.png';
  };

  const renderClubHeader = () => {
    const coverImage = getImageUrl(clubData?.data?.club_info?.cover_image);
    const profileImage = getImageUrl(clubData?.data?.club_info?.profile_image);
    const memberSince = moment(clubData?.data?.club_info?.created_at).fromNow();
    
    return (
      <>
        <div>
          <img className="club-cover-image" src={coverImage} alt="Club Cover" />
        </div>

        <Grid container className="club-info">
          <Grid item xs={2}>
            <div className="club-avatar-container">
              <img
                className="profile-image-container"
                src={profileImage}
                alt="Club Avatar"
              />
            </div>
          </Grid>
          <Grid item xs={10}>
            <div className="club-name">
              {clubData?.data?.club_info?.club_name}
            </div>

            <div className="club-time-details">
              Club created {memberSince}
            </div>

            <div style={{ display: "flex", gap: "10px", marginBottom: "10px" }}>
              {clubData?.data?.club_info?.category && (
                <div className="club-meta-info">
                  <span>Category: </span> {clubData.data.club_info.category}
                </div>
              )}
              {clubData?.data?.club_info?.member_count > 0 && (
                <div className="club-meta-info">
                  <span>Members: </span>
                  {clubData.data.club_info.member_count}
                </div>
              )}
              {clubData?.data?.club_info?.joining_fees && (
                <div className="club-meta-info">
                  <span>Joining Fees: </span> {clubData.data.club_info.joining_fees / 1e18} DRPD
                </div>
              )}
            </div>
            <div style={{ display: "flex", gap: "10px", marginBottom: "10px" }}>
              {clubData?.data?.club_info?.country && (
                <div className="club-meta-info">
                  <span>Country: </span> {clubData.data.club_info.country}
                </div>
              )}
              {clubData?.data?.club_info?.state && (
                <div className="club-meta-info">
                  <span>State: </span> {clubData.data.club_info.state}
                </div>
              )}
              {clubData?.data?.club_info?.city && (
                <div className="club-meta-info">
                  <span>City: </span> {clubData.data.club_info.city}
                </div>
              )}

              {clubData?.data?.club_info?.locality && (
                <div className="club-meta-info">
                  <span>Locality: </span> {clubData.data.club_info.locality}
                </div>
              )}
            </div>
          </Grid>
          <Grid item xs={12} style={{ display: "flex", flexDirection: "column" }}>
            <div className="club-button-wrap">
              {!clubData?.data?.club_info?.is_member ? (
                <button 
                  onClick={handleJoinClubWeb3}
                  className="club-join-button"
                > 
                  Join Club
                </button>
              ) : (
                <button 
                  onClick={() => history(`/club/${cid}/createpost`)} 
                  className="bg-white border-pink button"
                > 
                  Create Post
                </button>
              )}
            </div>
          </Grid>
        </Grid>
        <div className="club-description">
          <div className="profile-description-text">
            {clubData?.data?.club_info?.description}
          </div>
        </div>
      </>
    );
  };

  const renderClubContent = () => {
    const clubStatus = clubData?.data?.club_info?.status;

    if (clubStatus === 'pending') {
      return (
        <div className="pending-club-status-popup-container">
          <div className="pending-club-status-popup">
            <h2 
              style={{
                textAlign: 'center',
                marginBottom: '20px',
                color: '#EC1C80',
              }}
            >
              Status Pending
            </h2>

            <p>
              Your club is under review. If no DRPD tokens were deducted, please retry the payment. 
              Check your wallet transactions for confirmation. If tokens were deducted, no further action is needed.
            </p>

            {retryCount < MAX_RETRY_ATTEMPTS && (
              <div style={{ margin: '20px 0', textAlign: 'center', }}>
                <div className="activity-indicator">
                  <span className="rotating-icon">🔄</span> <span>Reviewing your request...</span>
                </div>
              </div>
            )}

            <div style={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              marginTop: '20px' 
            }}>
              <button 
                onClick={handleRetryPayment} 
                disabled={isRetryingPayment}
                style={{ 
                  width: '48%',
                  backgroundColor: isRetryingPayment ? '#cccccc' : '#EC1C80' 
                }}
              >
                {isRetryingPayment ? 'Retrying...' : 'Retry Payment'}
              </button>
              
              <button 
                className="wallet-button" 
                onClick={() => history('/transactions')}
                style={{ 
                  width: '48%',
                  backgroundColor: '#FFFFFF',
                  color: '#EC1C80',
                  border: '1px solid #EC1C80' 
                }}
              >
                Check Transactions
              </button>
            </div>
            
            {retryCount >= MAX_RETRY_ATTEMPTS && (
              <p className="retry-limit-message" style={{ color: '#EC1C80', marginTop: '20px' }}>
                We've checked the status, but no transaction has been completed. 
                Please retry the payment. If you face issues, contact support for help.
              </p>
            )}
          </div>
        </div>
      );
    }

    if (!clubData?.data?.club_info?.is_member) {
      return (
        <div className="join-club-prompt">
          <p>Join the club to view posts and members</p>
        </div>
      );
    }

    return (
      <>
        <div className="club-tabs">
          <button 
            className={`club-button ${activeTab === 'posts' ? 'active' : ''}`}
            onClick={() => setActiveTab('posts')}
          >
            Posts
          </button>
          <button 
            className={`club-button ${activeTab === 'members' ? 'active' : ''}`}
            onClick={() => setActiveTab('members')}
          >
            Members
          </button>
        </div>

        {activeTab === 'posts' && renderPosts()}
        {activeTab === 'members' && renderMembers()}
      </>
    );
  };

  const renderPosts = () => {
    const posts = clubData?.data?.club_info?.posts || [];
    return posts.length > 0 ? posts.map((item) => {
      const clubPostImage = item.images && item.images[0] 
        ? getImageUrl(item.images[0]) 
        : null;
      const postDate = moment(item.created_at).format("MMM D, h:mm a");

      return (
        <PostCard
          key={item.pid}
          postComments={item.comments.length}
          post_id={item.pid}
          did={item.post_from.did}
          postProfilePic={getImageUrl(clubData?.data?.club_info?.profile_image)}
          userName={item.post_from.nick_name}
          postText={item.text}
          postLikes={item.likes}
          postImage={clubPostImage}
          postDate={postDate}
          show_header={false}
          club_id={item.post_from.club_id}
          club_name={item.post_from.club_name}
          club_profile_image={REACT_APP_CDN_HOST + item.post_from.club_profile_image}
          club_created_at={moment(item.post_from.club_created_at).fromNow()}
        />
      );
    }) : (
      <div className="no-content-message">
        No posts yet. Be the first to create a post!
      </div>
    );
  };

  const renderMembers = () => {
    const members = membersData?.data?.members || [];
    return members.length > 0 ? members.map((item, index) => {
      const profileImage = item.profile_pics && item.profile_pics[0]
        ? getImageUrl(item.profile_pics[0])
        : '/path/to/default/avatar.png';

      return (
        <Grid key={index} container className="member-row">
          <Grid item xs={3}>
            <div className="member-avatar">
              <img 
                className="profile-image-container" 
                src={profileImage} 
                alt={item.nick_name}
              />
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="nick-name-alignment">{item.nick_name}</div>
          </Grid>
          <Grid className="club_friend_button-alignment" item xs={4}>
            <Friendbutton />
          </Grid>
        </Grid>
      );
    }) : (
      <div className="no-content-message">
        No members found
      </div>
    );
  };

  return (
    <div className="clubs-page-wrapper">
      {skeletonLoader ? (
        <SkeletonLoader />
      ) : (
        <div style={{ width: "100%" }}>
          <TimelineNav />
          <div className="club-details-wrapper">
            {renderClubHeader()}
            {renderClubContent()}
          </div>

          {transactionSuccess && (
            <TransactionConfirmationPopup 
              isOpen={showConfirmationPopup}
              onClose={() => {
                setShowConfirmationPopup(false);
              }}
              amount={RETRY_INTERVAL}
              transactionType={transactionDescription}
              // onRefreshBalance={fetchBalance}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default Clubpage;