import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import { messaging, getToken, onMessage } from "./firebase";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import theme from "./context/themecontext/Themecontext"
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Splash from "./pages/splash/splash";
import Login from "./pages/login/login";
import Home from "./pages/signup/signup";
import UserDetails from "./pages/userdetails/userDetails";
import EditUserDetails from "./pages/edituserdetails/edituserdetails.jsx";
import CreateProfile from "./pages/create/createProfile";
import Timeline from "./pages/timeline/timeline";
import Profilepage from "./pages/profilepage/profilepage";
import Socialpage from "./pages/socialpage/socialpage";
import Viewmatch from "./pages/viewmatch/viewmatch";
import Clubs from "./pages/clubs/clubs";
import CreateClub from "./pages/createclub/createclub";
import Notifications from "./pages/notifications/notifications"
import ConnectWallet from "./pages/connectwallet/connectwallet"
import CreateWallet from "./pages/connectwallet/Create"
import WalletApprovals from "./pages/connectwallet/Approvals"
import WalletTransactions from "./pages/connectwallet/WalletTransactions"

import Createpost from "./pages/createpost/createpost";
import CreatePage from "./pages/pages/parentPage/pagesParentPage";
import Pages from "./pages/pages/pages";
import Friends from "./pages/friends/friends";
import Menupage from "./pages/menupage/Menupage";
import Flics from "./pages/flics";
import MyWallet from "./pages/mywallet/mywallet";
import MyEarning from "./pages/myearning/myearning";
import SendTokens from "./pages/mywallet/components/SendTokens";
import Transactions from "./pages/transactions/transactions";
import NotFound from "./pages/notfound/notfound";
import Search from "./pages/search/search";

import i18n from "./i18n/i18n";
import Clubpage from "./pages/clubpage/clubpage";
import CircularIndeterminate from "./components/Loader/loader";
import Comments from "./pages/comments/comments";
import SkeletonLoader from "./components/SkeletonLoader/skeletonLoader";
import { I18nextProvider } from "react-i18next";
import ConnectWalletPage from "./pages/flics/components/connectwallet";
import FloatingToolbar from "./components/FloatingToolbar";
import Chatlist from "./pages/chat/chatlist/chatlist";
import Chat from "./pages/chat/chat";
import NotificationList from "./components/NotificationList/NotificationList";

import AuthGuard from './guards/AuthGuard';

import "./App.css";

import * as Sentry from "@sentry/react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: [/^\//, /^https:\/\/yourserver\.io\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});


function App({location}) {
  const [notifications, setNotifications] = useState([]);

  const handleNotificationClose = (id) => {
    setNotifications((prevNotifications) => prevNotifications.filter(notification => notification.id !== id));
  };

  useEffect(() => {
    const unsubscribe = onMessage(messaging, (payload) => {

      if (Notification.permission === "granted") {
        const newNotification = {
          id: Date.now(), // Unique ID for each notification
          title: payload.notification.title,
          body: payload.notification.body,
          route: payload.data.route,
          receiver_id: payload.data.receiver_id,
          sender_id: payload.data.sender_id,
          room_id: payload.data.misc,
          link: payload.data.link
        };

        setNotifications((prevNotifications) => {
          const updatedNotifications = [...prevNotifications, newNotification];
          return updatedNotifications;
        });

        // Optionally, display a system notification
        new Notification(payload.notification.title, {
          body: payload.notification.body,
          icon: payload.notification.icon
        });
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <Router>
      <ToastContainer/>
      <I18nextProvider i18n={i18n}>
        <ThemeProvider theme={theme}>
          <Routes>
            <Route exact path="/" element={<Home />} />

            <Route exact path="/create" element={
                <AuthGuard>
                  <CreateProfile />
                </AuthGuard>
              } />

            <Route exact path="/userdetails" element={
              <AuthGuard>
                <UserDetails />
              </AuthGuard>
            } />

            <Route exact path="/userdetails/:page" element={
              <AuthGuard>
                <UserDetails />
              </AuthGuard>
            } />

            <Route exact path="/edituserdetails" element={
              <AuthGuard>
                <EditUserDetails />
              </AuthGuard>
            } />

            <Route exact path="/timeline" element={
              <AuthGuard>
                <Timeline />
              </AuthGuard>
            } />
            
            <Route exact path="/comments/:pid" element={
              <AuthGuard>
                <Comments />
              </AuthGuard>
            } />

            <Route exact path="/createpost" element={
              <AuthGuard>
                <Createpost />
              </AuthGuard>
            } />
            
            <Route exact path="/profile/:did" element={
              <AuthGuard>
                <Profilepage />
              </AuthGuard>
            } />
            
            <Route exact path="/profile" element={
              <AuthGuard>
                <Profilepage />
              </AuthGuard>
            } />
            
            <Route exact path="/page/:pageId" element={
              <AuthGuard>
                <Socialpage />
              </AuthGuard>
            } />
            
            <Route exact path="/page/:pageId/createpost" element={
              <AuthGuard>
                <Createpost />
              </AuthGuard>
            } />
            
            <Route exact path="/viewmatch" element={
              <AuthGuard>
                <Viewmatch />
              </AuthGuard>
            } />
            
            <Route exact path="/skeleton" element={
              <AuthGuard>
                <SkeletonLoader />
              </AuthGuard>
            } />
            
            <Route exact path="/clublist" element={
              <AuthGuard>
                <Clubs />
              </AuthGuard>
            } />
            
            <Route exact path="/club/:cid" element={
              <AuthGuard>
                <Clubpage />
              </AuthGuard>
            } />
            
            <Route exact path="/club/:cid/createpost" element={
              <AuthGuard>
                <Createpost />
              </AuthGuard>
            } />
            
            <Route exact path="/createclub" element={
              <AuthGuard>
                <CreateClub />
              </AuthGuard>
            } />
            
            <Route exact path="/createpage" element={
              <AuthGuard>
                <CreatePage />
              </AuthGuard>
            } />
            
            <Route exact path="/pagelist" element={
              <AuthGuard>
                <Pages />
              </AuthGuard>
            } />
            
            <Route exact path="/notifications" element={
              <AuthGuard>
                <Notifications />
              </AuthGuard>
            } />
            
            <Route exact path="/subscription" element={
              <AuthGuard>
                <ConnectWallet />
              </AuthGuard>
            } />
            
            <Route exact path="/connectwallet" element={
              <AuthGuard>
                <ConnectWallet />
              </AuthGuard>
            } />
            
            <Route exact path="/wallet" element={
              <AuthGuard>
                <MyWallet />
              </AuthGuard>
            } />
            
            <Route exact path="/earning" element={
              <AuthGuard>
                <MyEarning />
              </AuthGuard>
            } />
            
            <Route exact path="/transactions" element={
              <AuthGuard>
                <Transactions />
              </AuthGuard>
            } />
            
            <Route exact path="/sendtokens" element={
              <AuthGuard>
                <SendTokens />
              </AuthGuard>
            } />

            <Route exact path="/wallet/create" element={
              <AuthGuard>
                <CreateWallet />
              </AuthGuard>
            } />
            
            <Route exact path="/wallet/approvals" element={
              <AuthGuard>
                <WalletApprovals />
              </AuthGuard>
            } />
            
            <Route exact path="/friends" element={
              <AuthGuard>
                <Friends />
              </AuthGuard>
            } />
            
            <Route exact path="/menu" element={
              <AuthGuard>
                <Menupage />
              </AuthGuard>
            } />
            
            <Route exact path="/flics" element={
              <AuthGuard>
                <Flics />
              </AuthGuard>
            } />

            
            <Route exact path="/chatlist" element={
              <AuthGuard>
                <Chatlist />
              </AuthGuard>
            } />
            
            <Route exact path="/chat" element={
              <AuthGuard>
                <Chat />
              </AuthGuard>
            } />
            
            <Route exact path="/search" element={
              <AuthGuard>
                <Search />
              </AuthGuard>
            } />

            <Route path="*" element={
              <AuthGuard>
                <NotFound />
              </AuthGuard>
            } />
          </Routes>

          
          {/* Render the NotificationList if there are notifications */}
          {notifications.length > 0 && (
            <NotificationList
              key={notifications.length}
              notifications={notifications}
              onClose={handleNotificationClose}
            />
          )}


          <div style={{ position: "fixed", transform: "translate(-50%,-50%)", left: "50%", top: "50%", zIndex: "9999" }}>
            <CircularIndeterminate />
          </div>
          {/* <FloatingToolbar/> */}
        </ThemeProvider>
      </I18nextProvider>
    </Router>
  );
}


export default App;