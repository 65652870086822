import {
  createSmartAccountClient,
  BiconomySmartAccountV2,
  PaymasterMode,
} from "@biconomy/account";
import { ethers } from "ethers";
import { flicsContractABI } from "../contract/flicsContractABI";
// 

import { chains } from "../chain";
import { toast } from "react-toastify";

export const sendTokensToAcceptFlics = async (authProvider, flicsContractAddress) => {
    toast.info("Sending ROCCA Accept Transaction. Please Wait.")
    const provider = new ethers.providers.JsonRpcProvider(
        chains.providerUrl
    );
    
    const flicsContractInstance = new ethers.Contract(
        flicsContractAddress,
        flicsContractABI,
        provider
    )

  try {
    const gasPrice = await provider.getGasPrice();
    const increasedGasPrice = gasPrice.mul(110).div(100);

    const nonce = await authProvider.getNonce();
    const minTx = await flicsContractInstance.populateTransaction.signAndAccept();
   
    const tx1 = {
      to: flicsContractAddress,
      data: minTx.data,
      gasPrice: increasedGasPrice
    };
    
    //@ts-ignore
    const userOpResponse = await authProvider?.sendTransaction(tx1, {
      paymasterServiceData: { mode: PaymasterMode.SPONSORED },
    });
    //@ts-ignore
    const { transactionHash } = await userOpResponse.waitForTxHash();
   
    const transactionReceipt = await userOpResponse.wait();
    
    if (transactionReceipt.success == "true") {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};
