import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { TextField } from "@mui/material";
import debounce from 'lodash/debounce';

// Import Assets
import search from "src/assets/images/search.svg";
import profileImage from "src/assets/images/avatar4.jpg";
import menukebab from "src/assets/images/menukebab.svg";

// import components
import TimelineNav from "../../components/timelinenav/timelineNav";
import { SEARCH_USER } from "../../axios/GET_API";
import "./search.css";

const { REACT_APP_CDN_HOST } = process.env;

function Search() {
  const history = useNavigate();
  const [searchInput, setSearchInput] = useState("");
  const [selectedZone, setSelectedZone] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [message, setMessage] = useState("");

  const options = [
    "Love Grounds",
    "Open Marriage Commune",
    "Seniors In Love Again",
  ];

  // Create a stable version of the search function
  const performSearch = async (searchText, zone, currentPage = 1, append = false) => {
    if (!searchText && !zone) {
      setSearchResults([]);
      setMessage("");
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const params = {
        p: currentPage
      };
      if (searchText?.trim()) {
        params.nick_name = searchText.trim();
      }
      if (zone) {
        params.zone = zone.toLowerCase();
      }

      console.log("Performing search with:", params);
      const result = await SEARCH_USER(params);
      console.log("search result", result);
      
      setMessage(result.message || "");
      
      if (append) {
        setSearchResults(prev => [...prev, ...(result.search_results || [])]);
      } else {
        setSearchResults(result.search_results || []);
      }
      
      setHasMore(result.hasMore);
    } catch (err) {
      console.error("Search error:", err);
      setError("Failed to perform search. Please try again.");
      setSearchResults([]);
    } finally {
      setLoading(false);
    }
  };

  // Handle infinite scroll
  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop
      === document.documentElement.offsetHeight
    ) {
      if (hasMore && !loading) {
        setPage(prev => prev + 1);
      }
    }
  }, [hasMore, loading]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  // Effect for pagination
  useEffect(() => {
    if (page > 1) {
      performSearch(searchInput, selectedZone, page, true);
    }
  }, [page]);

  // Debounced version of the search
  const debouncedSearch = useCallback(
    debounce((searchText, zone) => {
      setPage(1);
      performSearch(searchText, zone, 1, false);
    }, 500),
    []
  );

  // Effect to handle search when inputs change
  useEffect(() => {
    debouncedSearch(searchInput, selectedZone);
    return () => {
      debouncedSearch.cancel();
    };
  }, [searchInput, selectedZone, debouncedSearch]);

  // Handle zone selection
  const handleZoneChange = (option) => {
    setSearchInput(""); // Clear search input when zone is selected
    setSelectedZone(prev => prev === option ? "" : option);
  };

  // Handle input change
  const handleInputChange = (e) => {
    const value = e.target.value;
    setSelectedZone(""); // Clear zone selection when typing
    setSearchInput(value);
  };

  return (
    <div className="search-page">
      <TimelineNav />

      <div className="inner-pages-container-wrap">
        <h1 className="page-title">Search</h1>
       
        <div className="search-form">
          <div className="c-field search-field">
            <TextField 
              value={searchInput}
              onChange={handleInputChange}
              id="outlined-basic" 
              variant="outlined"
              size="small" 
              placeholder="Search Person"
              sx={{
                width: "340px",
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": {
                    border: "none"
                  }
                }
              }}
            /> 
            <span className="search-button">
              <img src={search} alt="search icon" />
            </span>
          </div> 
        </div>

        <div className="search-separator">
          <span>OR</span>
        </div>

        <div className="radio-list">
          {options.map((option) => (
            <label
              key={option}
              className={`radio-item ${selectedZone === option ? "selected" : ""}`}
            >
              <input
                type="radio"
                name="options"
                value={option}
                checked={selectedZone === option}
                onChange={() => handleZoneChange(option)}
              />
              {option}
            </label>
          ))}
        </div>

        {loading && page === 1 && <div className="search-loading">Searching...</div>}
        {error && <div className="search-error">{error}</div>}
        
        {!loading && !error && message && searchResults.length === 0 && (
          <div className="no-results">{message}</div>
        )}

        <div className="friend-list-container">
          {searchResults.map((user) => (
            <div key={user.did} className="user-row">
              <div className="u-thumb">
                <img 
                  onClick={() => history(`/profile/${user.did}`)} 
                  src={user.profile_pics?.[0] ? `${REACT_APP_CDN_HOST}${user.profile_pics[0]}` : profileImage} 
                  alt={user.nick_name}
                />
              </div>
              <div className="u-details">
                <div 
                  onClick={() => history(`/profile/${user.did}`)} 
                  className="u-name"
                >
                  {user.nick_name}
                </div>
              </div>
              <button className="kebab-menu-button">
                <img src={menukebab} alt="menu" />
              </button>
            </div>
          ))}
          
          {loading && page > 1 && (
            <div className="more-search-loading">Loading more results...</div>
          )}
          
          {!loading && !hasMore && searchResults.length > 0 && (
            <div className="search-end">No more results</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Search;