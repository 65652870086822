import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom";

// import components
import TimelineNav from "../../components/timelinenav/timelineNav";
import CustomButton from "src/components/Button/button";
import "./notfound.css"

function NotFound() {
  const history = useNavigate();

  return (
    <div style={{display: "flex", flexDirection: "column",}}>
      <TimelineNav/>
      <div className="notfound-container">
        <h1>404 - Page Not Found</h1>
        <p>The page you are looking for does not exist.</p>
        <CustomButton onClick={() => history(`/timeline`)}>Go to Timeline</CustomButton>
      </div>
    </div>
  )
}

export default NotFound;
