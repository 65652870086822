import { chains } from "../chain";
import {
  createSmartAccountClient,
  BiconomySmartAccountV2,
  PaymasterMode,
} from "@biconomy/account";
import { ethers } from "ethers";
import { contractABI } from "../contract/contractABI";
import { getWalletBalance } from "./getWalletBalance";
import { toast } from "react-toastify";

export const sendTokensPeerToPeer = async (authProvider, receiverAddress, amount) => {
  toast.info("Sending P2P Transaction. Please Wait.")
  const walletBalance = await getWalletBalance();
    if (walletBalance < amount) {
      alert('Insufficient Wallet Balance');
      return;
    }

  try {
    const contractAddress = chains.dropdContractAdd;
    const provider = new ethers.providers.JsonRpcProvider(
      chains.providerUrl
    );
    const contractInstance = new ethers.Contract(
      contractAddress,
      contractABI,
      provider
    );

    const transferAmount = ethers.utils.parseEther(amount.toString());

    const gasPrice = await provider.getGasPrice();
    const increasedGasPrice = gasPrice.mul(110).div(100);

    const minTx = await contractInstance.populateTransaction.transferP2P(receiverAddress, transferAmount);
    
    const tx1 = {
      to: contractAddress,
      data: minTx.data,
      gasPrice: increasedGasPrice
    };
    
    //@ts-ignore
    const userOpResponse = await authProvider?.sendTransaction(tx1, {
      paymasterServiceData: { mode: PaymasterMode.SPONSORED },
    });
    //@ts-ignore
    const { transactionHash } = await userOpResponse.waitForTxHash();
    
    const transactionReceipt = await userOpResponse.wait();
    
    if (transactionReceipt.success == "true") {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};